import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import {Button, Table, Pagination, ButtonGroup, ToggleButton, Spinner} from 'react-bootstrap';
import '../../appstyles/ClientsCSS/ClientsList.css';
import PermissionWrapper from '../../context/PermissionWrapper';

function CostList() {
    const axiosInstance = useAxiosPrivate();
    const [cost, setCost] = useState([]);
    const [valid, setValid] = useState(true)
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const rowsPerPage = 10;
    const navigate = useNavigate();

    const fetchCosts = (page) => {
        setLoading(true)
        const url = `api/quote-cost/?page=${page}&size=${rowsPerPage}&valid=${valid ? 'True' : 'False'}`;
    
          axiosInstance.get(url)
          .then(response => {
            setCost(response.data.data);
            setTotalPages(response.data.totalPages); 
          })
          .catch(error => {
            console.log('Failed to fetch costs', error);
          })
          .finally(() => {
            setLoading(false);
        });
    }

    const handleFilterChange = (event) => {
        setValid(event.target.value === 'true');
    };

    const radios = [
        { name: 'valido', value: 'true' },
        { name: 'invalido', value: 'false' }
    ];

    useEffect(() => {
        fetchCosts(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [currentPage, valid]);

    const handleRowClick = (costId) => {
    navigate(`/cost/details/${costId}`);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginationItems = [];
        for (let number = 1; number <= totalPages; number++) {
        // Only show a few items before and after the current page
        if (number === 1 || number === totalPages || (number >= currentPage - 2 && number <= currentPage + 2)) {
        paginationItems.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
            </Pagination.Item>
        );
        } else if (number === currentPage - 3 || number === currentPage + 3) {
        // Add ellipsis for hidden pages
        paginationItems.push(<Pagination.Ellipsis key={`ellipsis-${number}`} />);
        }
    } 

  return (
    <>
    <div className='maestros-list'>
    <h2>Tabla de Costos</h2>
    <hr />
    
    <PermissionWrapper feature="costos" action="create">
        <Button 
            variant="primary"
            style={{ 
                marginBottom: '20px', 
                width: '35%',
                backgroundColor: '#056664',
                border: ' solid 2px #025C5A',
            }}

            onClick={() => navigate('/costform')}
        >
                Crear Costeo
        </Button>
    </PermissionWrapper>

    <ButtonGroup toggle style={{width: '20%'}}>
    {radios.map((radio, idx) => (
        <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant={idx % 2 ? 'outline-danger' : 'outline-success'}
            name="radio"
            value={radio.value}
            checked={valid.toString() === radio.value}
            onChange={(e) => handleFilterChange(e)}
        >
            {radio.name}
        </ToggleButton>
        ))}
    </ButtonGroup>
    
    {loading ? ( 
        <div className='loading-container'>
        <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
        <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
        </div>
    ) : (   
        <Table striped bordered hover responsive
            style={{margin: '0px', overflowY: 'auto'}}
        >
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Cliente</th>
                    <th>Proveedor</th>
                    <th>Producto</th>
                    <th>Fabricante</th>
                    <th>Precio</th>
                    <th>Moneda</th>
                    <th>Incoterm</th>
                    <th>Estado</th>
                    <th>Fecha de validez</th>
                </tr>
            </thead>
            <tbody>
                {cost.map( cost => (
                    <tr key={cost.cost_id} onClick={() => handleRowClick(cost.cost_id)} style={{ cursor: 'pointer' }}>
                        <td>{cost.cost_id}</td>
                        <td>{cost.client_name}</td>
                        <td>{cost.supplier_name}</td>
                        <td>{cost.product_name}</td>
                        <td>{cost.product_manufacturer}</td>
                        <td>{cost.unit_price}</td>
                        <td>{cost.currency}</td>
                        <td>{cost.incoterm}</td>
                        <td>{cost.valid ? 'valido' : 'invalido'}</td>
                        <td>{cost.validity_date}</td>
                    </tr>
                ))}
            </tbody>
        </Table>  
    )}
    
    <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

        {paginationItems}

        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    </Pagination>
    </div>
    </>
  )
}

export default CostList