import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import {Table, Pagination, Spinner, ButtonGroup, ToggleButton} from 'react-bootstrap';
import '../../appstyles/ClientsCSS/ClientsList.css';

function OrderList() {
  const axiosInstance = useAxiosPrivate();
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState('Pendiente');
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const rowsPerPage = 10;
  const navigate = useNavigate();

  const fetchOrders = (page) => {
    setLoading(true)
    const url = `api/quote/?page=${page}&size=${rowsPerPage}&status=${status}`;

      axiosInstance.get(url)
      .then(response => {
        setOrders(response.data.data);
        setTotalPages(response.data.totalPages); 
      })
      .catch(error => {
        console.log('Failed to fetch requests', error);
      })
      .finally(() => {
        setLoading(false)
      });
  }

  const handleFilterChange = (event) => {
    setStatus(event.target.value);
  };

  const radios = [
    { name: 'Pendiente', value: 'Pendiente' },
    { name: 'Aprobado', value: 'Aprobado' },
    { name: 'Rechazado', value: 'Rechazado' }
  ];

  useEffect(() => {
      fetchOrders(currentPage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, status]);

  const handleRowClick = (quoteId) => {
  navigate(`/quote/details/${quoteId}`);
  };

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
  };

  const paginationItems = [];
      for (let number = 1; number <= totalPages; number++) {
      // Only show a few items before and after the current page
      if (number === 1 || number === totalPages || (number >= currentPage - 2 && number <= currentPage + 2)) {
      paginationItems.push(
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
          </Pagination.Item>
      );
      } else if (number === currentPage - 3 || number === currentPage + 3) {
      // Add ellipsis for hidden pages
      paginationItems.push(<Pagination.Ellipsis key={`ellipsis-${number}`} />);
      }
  } 

  return (
    <div className='maestros-list'>
    <h2>Tabla de Cotizaciones</h2>
    <hr />
    <div className='filter-container'>
    <ButtonGroup toggle style={{width: '20%'}}>
    {radios.map((radio, idx) => (
        <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant='outline-secondary'
            name="radio"
            value={radio.value}
            checked={status.toString() === radio.value}
            onChange={(e) => handleFilterChange(e)}
        >
            {radio.name}
        </ToggleButton>
        ))}
    </ButtonGroup>
    </div>

    {loading ? ( 
        <div className='loading-container'>
        <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
        <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
        </div>
    ) : (  
    <Table striped bordered hover responsive
      style={{overflowY: 'auto'}}
    >
        <thead>
            <tr>
                <th>ID</th>
                <th>Cliente</th>
                <th>Proveedor</th>
                <th>Producto</th>
                <th>Fabricante</th>
                <th>Incoterm</th>
                <th>Estado</th>
                <th>Pedido</th>
                <th>Fecha Solicitada</th>
                <th>Fecha de Creación</th>
                <th>Creado por</th>
            </tr>
        </thead>
        <tbody>
            {orders.map( order => (
                <tr key={order.quote_id} onClick={() => handleRowClick(order.quote_id)} style={{ cursor: 'pointer' }}>
                    <td>{order.quote_id}</td>
                    <td>{order.client_name}</td>
                    <td>{order.supplier_name}</td>
                    <td>
                      {order.products.length > 0 ? order.products[0].product_name : ''}
                      {order.products.length > 1 ? ', ...' : ''}
                    </td>
                    <td>
                      {order.products.length > 0 ? order.products[0].product_manufacturer : ''}
                      {order.products.length > 1 ? ', ...' : ''}
                    </td>
                    <td>{order.incoterm}</td>
                    <td>{order.status}</td>
                    <td>{order.products.length > 1 ? 'Combinado' : 'Individual'}</td>
                    <td>{order.requested_delivery_date}</td>
                    <td>{order.requested_date}</td>
                    <td>{order.created_by ? order.created_by : 'N/A' }</td>
                </tr>
            ))}
        </tbody>
    </Table>
    )}
    <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

        {paginationItems}

        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    </Pagination>
    </div>
  )
}

export default OrderList