import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import { Spinner, Modal, Button, Row } from 'react-bootstrap';
import '../../appstyles/ClientsCSS/ClientsDetails.css'
import RequestSupplier from './RequestSupplier';
import PermissionWrapper from '../../context/PermissionWrapper';

const unitNames = {
    KG: 'Kilogramos',
    G: 'Gramos',
    TON: 'Tonelada',
    TON_S: 'Tonelada corta',
    TON_L: 'Tonelada larga',
    LB: 'Libra',
    OZ: 'Onzas',
    L: 'Litro',
    ML: 'Mililitro',
    GAL_UK: 'Galón UK',
    GAL_US: 'Galón US',
    FCL: 'FCL',
  };

function RequestDetails() {
  const axiosInstance = useAxiosPrivate();
  const { requestId } = useParams();
  const [request, setRequest] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showSupplier, setShowSupplier] = useState(false);

  useEffect(() => {
    axiosInstance.get(`api/quote/${requestId}/`)
      .then(response => {
        setRequest(response.data);
      })
      .catch(error => {
        console.error('Failed to fetch request details', error);
      });
  }, [requestId, axiosInstance]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const confirmDelete = () => {
    axiosInstance.delete(`api/quote/${requestId}/`)
      .then(() => {
        navigate('/requestlist');
      })
      .catch(error => {
        console.error('Failed to delete request', error);
      });
  };

  //selección de proveedor
  const handleSelectSupplier = (supplier) => {
    if (typeof setSelectedSupplier === 'function') {
      if (selectedSupplier === null) {
        setSelectedSupplier(supplier.supplier_id);
        console.log('ID Marcado:', supplier.supplier_id);

      } else if (selectedSupplier === supplier.supplier_id) {
        setSelectedSupplier(null);
        console.log('ID Desmarcado:', supplier.supplier_id);

      } else {
        console.log('Ya hay un proveedor seleccionado:', selectedSupplier);
      }
    } else {
      console.error('setSelectedSupplier is not a function');
      console.log('Fallido:', supplier);
    }
  };

  //guardar proveedor seleccionado
  const handleSaveSelection = async () => {
    try {
      await axiosInstance.patch(`/api/quote/${requestId}/`, {
        supplier_id: selectedSupplier,
      });
      setShowSupplier(false);
    } catch (error) {
    } finally {
      window.location.reload();
    }
  };

  const handleButtonClick = () => {
    if (request.supplier_id) {
      navigate(`/requestcost/${requestId}`);
    } else {
      setShowSupplier(true);
    }
  };

  const handleEditClick = () => {
    navigate(`/requestform/${requestId}`);
  };


  if (!request) {
    return(
    <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
    </div>
    );
  }

  return (
    <div className='maestros-details'>

        <h2>Detalles de la Solicitud</h2>
        <hr />
        <div className='maestros-details-actions' style={{display: 'flex', flexDirection: 'row'}}>
        <h4>Solicitud No. {request.quote_id}</h4>

        <PermissionWrapper feature="solicitud" action="update">
            <button 
            className='btn btn-primary' 
            style={{backgroundColor: '#025C5A', borderColor: '#025C5A '}}
            onClick={handleEditClick}
            > 
            Editar
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
            </svg>
            </button>
        </PermissionWrapper>
        
        <PermissionWrapper feature="solicitud" action="delete">
            <button className='btn btn-danger' onClick={handleShow}>
            Eliminar
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
            </svg>
            </button>
        </PermissionWrapper>

        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
            </svg>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h5>Estas a punto de eliminar la solicitud <strong>No. {request.quote_id}</strong> </h5>
            <p>¿Estás seguro de que la deseas eliminar? Esta acción no podrá ser revertida</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
            Cerrar
            </Button>
            <Button variant="danger" onClick={confirmDelete}>
            Eliminar
            </Button>
        </Modal.Footer>
        </Modal>  
        </div>

        <h4 style={{fontWeight: '700', color: '#07514F'}}>{request.products.length > 1 ? 'Productos' : 'Producto'}</h4>
        {request.products.map(product => (
        <Row key={product.id}>
        <div className='first-col'>
            <p><strong>Id del producto:</strong> {product.product_id}</p>
            <p><strong>Nombre del producto:</strong> {product.product_name} </p>
            <p><strong>Fabricante:</strong> {product.product_manufacturer} </p>
        </div>

        <div className='second-col'>
            <p><strong>Cantidad:</strong> {product.quantity}</p>
            <p><strong>Unidad:</strong> {unitNames[product.quantity_unit]}</p>
        </div>
        <hr />
        </Row>
        ))}

        <h4 style={{fontWeight: '700', color: '#07514F'}}>Solicitud</h4>
        <div style={{display: 'flex', flexDirection: 'row'}}>
        <div className='first-col'>
        <p><strong>ID del Cliente:</strong> {request.client_id} </p>
        <p><strong>Nombre del Cliente:</strong> {request.client_name} </p>
        <p><strong>Incoterm:</strong> {request.incoterm} </p>
        <p><strong>Terminos de pago (días):</strong> {request.payment_terms} </p>
        <p><strong>Tipo de compra:</strong> {request.buy_type} </p>
        </div>  

        <div className='second-col'>
        <p><strong>Fecha estimada de despacho:</strong> {request.requested_delivery_date} </p>
        <p><strong>Ciudad de llegada:</strong> {request.arrival_city} </p>
        <p><strong>Detalles de entrega:</strong> {request.delivery_details ? request.delivery_details : 'N/A'} </p>
        <p><strong>ID del proveedor:</strong> {request.supplier_id ? request.supplier_id : 'No se ha seleccionado proveedor' } </p>
        <p><strong>Nombre del proveedor:</strong> {request.supplier_id ? request.supplier_name : 'No se ha seleccionado proveedor'} </p>
        </div>
        </div>

        {/*Botones*/}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px',
          }}
        >
        <Button 
          variant="secondary" 
          style={{marginTop: '20px', border: '2px solid gray'}}
          onClick={() => navigate(`/requestlist`)}
          >
            Volver
        </Button>
        
        <PermissionWrapper feature="costos" action="create">
        <Button 
            variant='primary'
            style={{marginTop: '20px', width: '30%', border: '2px solid #07514F', backgroundColor: '#025C5A'}}
            onClick={handleButtonClick}
        >
          {request.supplier_id ? 'Iniciar Costeo' : 'Asignar Proveedor'}
        </Button>
        </PermissionWrapper>
        </div>

        {showSupplier && !request.supplier_id &&(
          <RequestSupplier 
            selectedSupplier={selectedSupplier}
            handleSelectSupplier={handleSelectSupplier}
          />
        )}

        {!request.supplier_id && selectedSupplier && (
          <Button 
            variant='success'
            style={{marginTop: '20px', width: '30%', border: '2px solid #07514F', backgroundColor: '#025C5A'}}
            onClick={handleSaveSelection}
          >
            Guardar Proveedor
          </Button>
        )}

    </div>
  )
}

export default RequestDetails