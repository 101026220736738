import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import {Form, Button, Row, Col, Spinner} from 'react-bootstrap';
import RequestClient from './RequestClient';
import RequestProduct from './RequestProduct';

const requiredField = yup.string().required('Este campo es requerido');

const schema = yup.object().shape({
  products: yup.array().of(
    yup.object().shape({
      product_id: yup.number().typeError('Debe ser un número').required('Este campo es requerido'),
      quantity: yup.string().required('Este campo es requerido').matches(/^\d+(\.\d+)?$/, 'Debe ser un número'),
      quantity_unit: yup.string().required('Este campo es requerido'),
    })
  ),
  client_id: yup.number().required('Este campo es requerido'),
  incoterm: yup.string().required('Este campo es requerido'),
  requested_delivery_date: yup.date().nullable().typeError('Debe ingresar una fecha').required('Este campo es requerido'),
  arrival_city: requiredField,
  payment_terms: yup.number().typeError('Debe ser un número').required('Este campo es requerido').positive().integer(),
  buy_type: requiredField,
  delivery_details: requiredField,
});

function RequestForm() {
  const axiosInstance = useAxiosPrivate();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientName, setClientName] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isMultipleSelection, setIsMultipleSelection] = useState(false);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
        products: [
          {
            quantity_unit: 'KG',
          },
        ],
        incoterm: 'Local',
        buy_type: 'Frecuente',
      }
  });

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  //Selección de cliente
  const handleSelectClient = (client) => {
    if (typeof setSelectedClient === 'function') {
      if (selectedClient === null) {
        setSelectedClient(client.client_id);
        setClientName(client.client_name);

      } else if (selectedClient === client.client_id) {
        setSelectedClient(null);
        setClientName('');

      } else {
        console.log('Ya hay un cliente seleccionado:', selectedClient);
      }
    } else {
      console.error('setSelectedClient is not a function');
    }
  };

  //Selección de producto
  const handleSelectProduct = (product) => {
    if (isMultipleSelection) {
      if (selectedProducts.some(item => item.product_id === product.product_id)) {
          setSelectedProducts(selectedProducts.filter(item => item.product_id !== product.product_id));
      } else {
          setSelectedProducts([...selectedProducts, { 
            product_id: product.product_id, 
            product_name: product.product_name, 
            quantity: product.quantity || '',
            quantity_unit: product.quantity_unit || 'KG' 
          }]); 
      }
      
    } else {
        if (selectedProducts.some(item => item.product_id === product.product_id)) {
          setSelectedProducts([]);
      } else {
          setSelectedProducts([{ 
            product_id: product.product_id, 
            product_name: product.product_name,
            quantity: product.quantity || '', 
            quantity_unit: product.quantity_unit || 'KG' 
          }]);
      }
    }
  };

  useEffect(() => {
    if (selectedClient) {
      setValue('client_id', selectedClient);
    } else {  
      setValue('client_id', '');  
    }
    if (selectedProducts.length > 0) {
        const idProducts = selectedProducts.map(({ product_id }) => ({
          product_id,
        }));
      setValue('products', idProducts);
    } else {
      setValue('products', ''); 
    }
  }, [selectedClient, selectedProducts, setValue]);
  
  const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };
  
  //enviar formulario
  const onSubmit = async (data) => {  
    setLoading(true);
    const formattedData = {
      ...data,
      requested_delivery_date: formatDate(data.requested_delivery_date),
    };
    
    try{
      setLoading(true);
      await axiosInstance.post('api/quote/', formattedData);

      setTimeout(() => {
        navigate(`/requestlist`);
      }, 1000);

    } catch (error) {
      console.error('Failed to submit request:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return(
    <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
    </div>
    );
  }

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <h2>Selecciona un Cliente</h2>
            <p style={{margin: '5px 0', fontSize: '17px'}}>
              Selecciona un cliente para continuar con la solicitud de cotización.
            </p>
            <hr />
            <RequestClient 
              selectedClient={selectedClient} 
              handleSelectClient={handleSelectClient}
            /> 
            {selectedClient && (
              <Button onClick={handleNextStep}>Siguiente: Producto</Button>
            )}
          </>
        );
      case 2:
        return (
          <>
            <h2>Selecciona un Producto</h2>
            <p style={{margin: '5px 0', fontSize: '17px'}}>
              Selecciona un producto para continuar con la solicitud de cotización.
            </p>
            <Form.Check 
              type="checkbox"
              label="Habilitar solicitud combinada"
              checked={isMultipleSelection}
              onChange={(e) => {
                setIsMultipleSelection(e.target.checked);
                setSelectedProducts([]); 
            }}
              style={{margin: '10px 0', padding: '0px'}}  
            />
            <hr />
            <RequestProduct 
              selectedProducts={selectedProducts} 
              isMultipleSelection={isMultipleSelection}
              handleSelectProduct={handleSelectProduct}
            /> 
            {selectedProducts.length > 0 && (
              <Button onClick={handleNextStep}>Siguiente: Formulario</Button>
            )}
            <Button onClick={handlePrevStep}>Volver a Selección de Cliente</Button>
          </>
        );
      case 3:
        return (
          <>
            <h2>Completa el Formulario</h2>
            <p style={{margin: '5px 0', fontSize: '17px'}}>
                Diligencia los campos para enviar la solicitud correspondiente al cliente: <strong>{clientName ? clientName : 'Cliente no seleccionado'}</strong>.
            </p>
            <hr />
            <Form onSubmit={handleSubmit(onSubmit)}>
            <h4 style={{fontWeight: '700', color: '#07514F'}}>Información del Producto</h4>
            {selectedProducts.map((product, index) => (
              <Row key={ index }>
                <h5 style={{fontWeight: '500'}}> <strong>Producto seleccionado:</strong> {product.product_name}</h5>
                {/* Primera columna */}
                <Col>
                <Form.Group controlId={`products[${index}].product_id`}>
                  <Form.Label>ID del producto</Form.Label>
                  <Form.Control 
                    type="number" 
                    name={`products[${index}].product_id`} 
                    {...register(`products[${index}].product_id`)} 
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.products?.[index]?.product_id?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId={`products[${index}].quantity`}>
                  <Form.Label>Cantidad</Form.Label>
                  <Form.Control
                    type="number"
                    name={`products[${index}].quantity`}
                    {...register(`products[${index}].quantity`)}
                    isInvalid={!!errors.products?.[index]?.quantity}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.products?.[index]?.quantity?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                </Col>
                {/* Segunda columna */}
                <Col>
                <Form.Group controlId={`products[${index}].quantity_unit`}>
                  <Form.Label>Unidad</Form.Label>
                  <Form.Select
                    name={`products[${index}].quantity_unit`}
                    {...register(`products[${index}].quantity_unit`)}
                    isInvalid={!!errors.products?.[index]?.quantity_unit}
                  >
                    <option value="KG">Kilogramos</option>
                    <option value="G">Gramos</option>
                    <option value="TON">Tonelada</option>
                    <option value="TON_S">Tonelada corta</option>
                    <option value="TON_L">Tonelada larga</option>
                    <option value="LB">Libra</option>
                    <option value="OZ">Onzas</option>
                    <option value="L">Litro</option>
                    <option value="ML">Mililtro</option>
                    <option value="GAL_UK">Galón UK</option>
                    <option value="GAL_US">Galón US</option>
                    <option value="FCL">FCL</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.products?.[index]?.quantity_unit?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                </Col>
              </Row>
            ))}

              <hr />
              <h4 style={{fontWeight: '700', color: '#07514F'}}>Información de la solicitud</h4>  
              <Row>
                {/* Primera columna */}
                <Col>
                <Form.Group controlId='client_id'>
                    <Form.Label>ID del cliente</Form.Label>
                    <Form.Control type="number" name="client_id" {...register('client_id')} disabled/>
                    <Form.Control.Feedback type="invalid">
                      {errors.client_id?.message}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='incoterm'>
                  <Form.Label>Incoterm</Form.Label>
                  <Form.Select type="text" name="incoterm" {...register('incoterm')} isInvalid={!!errors.incoterm}>
                    <option value="Local">Local</option>
                    <option value="CIF">CIF</option>
                    <option value="CIP">CIP</option>
                    <option value="FOB">FOB</option>
                    <option value="EXW">EXW</option>
                    <option value="FCA">FCA</option>
                    <option value="FAS">FAS</option>
                    <option value="CFR">CFR</option>
                    <option value="CPT">CPT</option>
                    <option value="DPU">DPU</option>
                    <option value="DAP">DAP</option>
                    <option value="DDP">DDP</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.incoterm?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='payment_terms'>
                  <Form.Label>Terminos de pago (días)</Form.Label>
                  <Form.Control 
                    type="number" 
                    name="payment_terms" 
                    pattern="^[0-9]+$"  
                    min='0'
                    onKeyDown={(e) => e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null}
                    onWheel={(e) => e.preventDefault()}
                    {...register('payment_terms')}
                    isInvalid={!!errors.payment_terms}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.payment_terms?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='buy_type'>
                  <Form.Label>Tipo de compra</Form.Label>
                  <Form.Select type="text" name="buy_type" {...register('buy_type')} isInvalid={!!errors.buy_type}>
                    <option value="Frecuente">Frecuente</option>
                    <option value="Desarrollo">Desarrollo</option>
                    <option value="Homologación">Homologación</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.incoterm?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                </Col>

                {/* Segunda columna */}
                <Col>
                <Form.Group controlId='requested_delivery_date'>
                  <Form.Label>Fecha estimada de despacho</Form.Label>
                  <Form.Control 
                    type="date" 
                    name="requested_delivery_date" 
                    {...register('requested_delivery_date')} 
                    isInvalid={!!errors.requested_delivery_date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.requested_delivery_date?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='arrival_city'>
                  <Form.Label>Ciudad de llegada</Form.Label>
                  <Form.Control type="text" name="arrival_city" {...register('arrival_city')} isInvalid={!!errors.arrival_city} />
                  <Form.Control.Feedback type="invalid">
                    {errors.arrival_city?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='delivery_details'>
                  <Form.Label>Detalles de entrega</Form.Label>
                  <Form.Control type="text" name="delivery_details" {...register('delivery_details')} isInvalid={!!errors.delivery_details} />
                  <Form.Control.Feedback type="invalid">
                    {errors.delivery_details?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                </Col>
              </Row>

              <Button type='submit'>Crear Solicitud</Button>
              <Button onClick={handlePrevStep}>Volver a Selección de Producto</Button>
            </Form>
          </>
        );
      default:
        return <div>Ocurrió un error, por favor refresca la página.</div>;
    }
  };

  return (
    <div className='maestros-form'>
      {renderStep()}
    </div>
  )
}

export default RequestForm