import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Logout from '../components/Logout';
import AuthContext from '../context/AuthContext';
import rolesPermissions from '../context/Roles';
import PermissionWrapper from '../context/PermissionWrapper';
//Styles
import { Nav, Accordion } from 'react-bootstrap';
import '../appstyles/Sidebar.css';

const role = {
  ADMIN : 'Administrador',
  DEV : 'Desarrollador',
  CEO: 'Director General',
  DTCA: 'Director de Calidad',
  DTLO: 'Director Logístico',
  ANAD: 'Analista Administrativo',
  DTAD: 'Director Administrativo',
  DTFI: 'Director Financiero',
  ANCO: 'Analista de Compras',
  DTCO: 'Director Comercial',
  ASCO: 'Asesor Comercial',
  AUBO: 'Auxiliar de Bodega',
  CLI: 'Cliente'
}

const hasAnyPermission = (role, feature, actions) => {
  return actions.some(action => rolesPermissions[role]?.[feature]?.[action]);
};
const hasAnyPermissionForFeatures = (role, features, actions) => {
  return features.some(feature => hasAnyPermission(role, feature, actions));
};

function Sidebar() {
  const { auth } = useContext(AuthContext);
  const displayRole = role[auth?.role] || 'sin rol';

  //conditinal renderin of items
  const canAccessClients = auth?.role && hasAnyPermission(auth?.role, 'cliente', ['create', 'read', 'update', 'delete']);
  const canAccessProducts = auth?.role && hasAnyPermission(auth?.role, 'producto', ['create', 'read', 'update', 'delete']);
  const canAccessSuppliers = auth?.role && hasAnyPermission(auth?.role, 'proveedor', ['create', 'read', 'update', 'delete']);
  const canAccessOffers = auth?.role && hasAnyPermissionForFeatures(auth?.role, ['solicitudes', 'costos', 'cotizaciones'], ['create', 'read', 'update', 'delete']);
  const canAccessLogistics = auth?.role && hasAnyPermissionForFeatures(auth?.role, ['trazabilidad', 'inventario', 'ordenes'], ['create', 'read', 'update', 'delete']);

  return (
    <Nav className="sidebar">
      <div className="profile-container">
        <div className="profile-info">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
          <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
        </svg>
          {/*users info*/}
          <div>
            <h5 style={{textTransform: 'capitalize'}}>{auth?.username || 'Guest'}</h5>
            <p style={{textTransform: 'capitalize'}}>{displayRole}</p>
          </div>
        </div>
        <hr/>
      </div>
      <div className="menu-container">
      <Nav.Link as={Link} to="/home" className='custom-link'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house" viewBox="0 0 16 16">
        <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z"/>
      </svg>
        Inicio
      </Nav.Link>
        <Accordion defaultActiveKey="0" flush>

        {canAccessClients && (
          <Accordion.Item eventKey="0">
            <Accordion.Header className='custom-accordion-header'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
              <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
            </svg>
              Clientes
            </Accordion.Header>
            <Accordion.Body>
              <ul>
              <PermissionWrapper feature="cliente" action="read">
                <Nav.Link as={Link} to='/clientslist'>Consultar</Nav.Link>
              </PermissionWrapper>
              <PermissionWrapper feature="cliente" action="create">
                <Nav.Link as={Link} to='/clientsform'>Crear</Nav.Link>
              </PermissionWrapper>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        )}        
        
        {canAccessProducts && (
        <Accordion.Item eventKey="1">
          <Accordion.Header>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
          </svg>
            Productos
          </Accordion.Header>
          <Accordion.Body>
            <ul>
            <PermissionWrapper feature="producto" action="read">
              <Nav.Link as={Link} to='/productslist'>Consultar</Nav.Link>
            </PermissionWrapper>
            <PermissionWrapper feature="producto" action="create">
              <Nav.Link as={Link} to='/productsform'>Crear</Nav.Link>
            </PermissionWrapper>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        )}
        
        {canAccessSuppliers && (
        <Accordion.Item eventKey="2">
          <Accordion.Header>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
          </svg>
            Proveedores
          </Accordion.Header>
          <Accordion.Body>
            <ul>
            <PermissionWrapper feature="proveedor" action="read">
              <Nav.Link as={Link} to='/supplierslist'>Consultar</Nav.Link>
            </PermissionWrapper>
            <PermissionWrapper feature="proveedor" action="create">
              <Nav.Link as={Link} to='/suppliersform'>Crear</Nav.Link>
            </PermissionWrapper>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        )}

        {canAccessOffers && (
        <Accordion.Item eventKey="3">
          <Accordion.Header>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-coin" viewBox="0 0 16 16">
            <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z"/>
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
          </svg>
            Ofertas
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <Nav.Link as={Link} to='/requestlist'>Solicitudes</Nav.Link>
              <Nav.Link as={Link} to='/costlist'>Costos</Nav.Link>
              <Nav.Link as={Link} to='/quoteslist'>Cotizaciones</Nav.Link>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        )}

        {canAccessLogistics && (
        <Accordion.Item eventKey="4">
          <Accordion.Header>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-truck" viewBox="0 0 16 16">
            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>
          </svg>
            Logístico
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <Nav.Link as={Link} to='/orderslist'>Ordenes de Compra</Nav.Link>
              <Nav.Link as={Link} to='/trackinglist'>Trazabilidad</Nav.Link>
              <Nav.Link as={Link} to='/clientes'>Inventario</Nav.Link>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        )}

        <Accordion.Item eventKey="5">
          <Accordion.Header>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt" viewBox="0 0 16 16">
            <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z"/>
            <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5"/>
          </svg>
            Facturación
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <Nav.Link as={Link} to='/clientes'>Consultar</Nav.Link>
              <Nav.Link as={Link} to='/clientes'>Crear</Nav.Link>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        </Accordion>
        <hr/>
      </div>
      
      <div className='settings'>
        <p style={{justifyContent: 'flex-start', color: '#757575'}}>Ajustes</p>
        <Nav.Link as={Link} to="/settings">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
          </svg>
          Configuración
        </Nav.Link>
        <Logout />
      </div>
      </Nav>
  );
}

export default Sidebar;
