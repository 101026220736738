import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import {Table, Pagination, Spinner} from 'react-bootstrap';
import '../../appstyles/ClientsCSS/ClientsList.css';

function TrackingList() {
  const axiosInstance = useAxiosPrivate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const rowsPerPage = 10;
  const navigate = useNavigate();

  const fetchOrders = (page) => {
    setLoading(true)
    const url = `api/logistics-tracking/?page=${page}&size=${rowsPerPage}`;

      axiosInstance.get(url)
      .then(response => {
        setOrders(response.data.data);
        setTotalPages(response.data.totalPages); 
      })
      .catch(error => {
        console.log('Failed to fetch orders', error);
      })
      .finally(() => {
        setLoading(false)
      });
  }

  useEffect(() => {
      fetchOrders(currentPage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

  const handleRowClick = (trackId) => {
        navigate(`/traceability/${trackId}`);
  };

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
  };

  const paginationItems = [];
      for (let number = 1; number <= totalPages; number++) {
      // Only show a few items before and after the current page
      if (number === 1 || number === totalPages || (number >= currentPage - 2 && number <= currentPage + 2)) {
      paginationItems.push(
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
          </Pagination.Item>
      );
      } else if (number === currentPage - 3 || number === currentPage + 3) {
      // Add ellipsis for hidden pages
      paginationItems.push(<Pagination.Ellipsis key={`ellipsis-${number}`} />);
      }
  } 

  return (
    <div className='maestros-list'>
    <h2>Trazabilidad Ordenes de Compra</h2>
    <hr />

    {loading ? ( 
        <div className='loading-container'>
        <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
        <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
        </div>
    ) : (  
    <Table striped bordered hover responsive
      style={{overflowY: 'auto'}}
    >
        <thead>
            <tr>
                <th>ID</th>
                <th>Cliente</th>
                <th style={{ minWidth: '150px' }}>Producto</th>
                <th>Fabricante</th>
                <th>Estado</th>
                <th style={{ minWidth: '150px' }}>Consecutivo Cliente</th>
                <th style={{ minWidth: '150px' }}>Consecutivo Proveedor</th>
            </tr>
        </thead>
        <tbody>
            {orders.map( order => (
                <tr key={order.tracking_id} onClick={() => handleRowClick(order.tracking_id)} style={{ cursor: 'pointer' }}>
                    <td>{order.quote_id}</td>
                    <td>{order.quote_details.client_name}</td>
                    <td style={{ minWidth: '150px' }}>{order.quote_details.product_names}</td>
                    <td>{order.quote_details.product_manufacturers}</td>
                    <td>{order.status}</td>
                    <td style={{ minWidth: '150px' }}>{order.quote_details.client_consecutive ? order.quote_details.client_consecutive : 'N/A'}</td>
                    <td style={{ minWidth: '150px' }}>{order.supplier_consecutive ? order.supplier_consecutive : 'N/A'}</td>
                </tr>
            ))}
        </tbody>
    </Table>
    )}
    <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

        {paginationItems}

        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    </Pagination>
    </div>
  )
}

export default TrackingList