import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import {Button, Table, Pagination, Spinner} from 'react-bootstrap';
import '../../appstyles/ClientsCSS/ClientsList.css';
import PermissionWrapper from '../../context/PermissionWrapper';

function RequestList() {
    const axiosInstance = useAxiosPrivate();
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const rowsPerPage = 10;
    const navigate = useNavigate();

    const fetchRequests = (page) => {
        setLoading(true)
        const url = `api/quote/?page=${page}&size=${rowsPerPage}&status=Solicitado`;
    
          axiosInstance.get(url)
          .then(response => {
            setRequests(response.data.data);
            setTotalPages(response.data.totalPages); 
          })
          .catch(error => {
            console.log('Failed to fetch requests', error);
          })
          .finally(() => {
            setLoading(false)
          });
    }

    useEffect(() => {
        fetchRequests(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [currentPage]);

    const handleRowClick = (requestId) => {
    navigate(`/request/details/${requestId}`);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginationItems = [];
        for (let number = 1; number <= totalPages; number++) {
        // Only show a few items before and after the current page
        if (number === 1 || number === totalPages || (number >= currentPage - 2 && number <= currentPage + 2)) {
        paginationItems.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
            </Pagination.Item>
        );
        } else if (number === currentPage - 3 || number === currentPage + 3) {
        // Add ellipsis for hidden pages
        paginationItems.push(<Pagination.Ellipsis key={`ellipsis-${number}`} />);
        }
    } 

  return (
    <div className='maestros-list'>
    <h2>Tabla de Solicitudes</h2>
    <hr />
    <PermissionWrapper feature="solicitud" action="create">
    <Button 
        variant="primary"
        style={{ 
            marginBottom: '20px', 
            width: '35%',
            backgroundColor: '#056664',
            border: ' solid 2px #025C5A',
        }}

        onClick={() => navigate('/requestform')}
    >
        Crear nueva solicitud
    </Button>
    </PermissionWrapper>
    {loading ? ( 
        <div className='loading-container'>
        <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
        <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
        </div>
    ) : (  
    <Table striped bordered hover responsive
        style={{overflowY: 'auto'}}
    >
        <thead>
            <tr>
                <th>ID</th>
                <th>Cliente</th>
                <th>Producto</th>
                <th>Fabricante</th>
                <th>Pedido</th>
                <th>Incoterm</th>
                <th>Fecha Solicitada</th>
                <th>Fecha de Creación</th>
                <th>Creado por</th>
            </tr>
        </thead>
        <tbody>
            {requests.map( request => (
                <tr key={request.quote_id} onClick={() => handleRowClick(request.quote_id)} style={{ cursor: 'pointer' }}>
                    <td>{request.quote_id}</td>
                    <td>{request.client_name}</td>
                    <td>
                      {request.products.length > 0 ? request.products[0].product_name : ''}
                      {request.products.length > 1 ? ', ...' : ''}
                    </td>
                    <td>
                      {request.products.length > 0 ? request.products[0].product_manufacturer : ''}
                      {request.products.length > 1 ? ', ...' : ''}
                    </td>
                    <td>{request.products.length > 1 ? 'Combinado' : 'Individual'}</td>
                    <td>{request.incoterm}</td>
                    <td>{request.requested_delivery_date}</td>
                    <td>{request.requested_date}</td>
                    <td>{request.created_by ? request.created_by : 'N/A' }</td>
                </tr>
            ))}
        </tbody>
    </Table>
    )}
    <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

        {paginationItems}

        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    </Pagination>
    </div>
  )
}

export default RequestList