import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';

const SupplierPDF = () => {
    const axiosInstance = useAxiosPrivate()
    const { orderId } = useParams();
    const [loading, setLoading] = useState(false);

    const handleExport = async () => {
        setLoading(true);
        try {
        const response = await axiosInstance.get(`api/quote/${orderId}/order-supplier-pdf/`, 
            {
            headers: {'content-type': 'blob'},
            responseType: 'arraybuffer',
            maxRedirects: 2,
            }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        
        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `cotizacion_${orderId}.pdf`);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        } catch (error) {
        console.error('Error exporting PDF file:', error);
        console.log(error)
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {loading ? (
                <button
                    style={{
                        width: '100%', 
                        marginLeft: '5px', 
                        border: 'none', 
                        fontSize: '1rem', 
                        color: '#54203F', 
                        backgroundColor:'transparent' , 
                        fontWeight: 'bold'
                    }} 
                    disabled
                >
                    descargando...
                </button>
            ) : (

                <button
                style={{
                    width: '100%', 
                    marginLeft: '5px', 
                    border: 'none', 
                    fontSize: '1rem', 
                    color: '#54203F', 
                    backgroundColor:'transparent' , 
                    fontWeight: 'bold'
                }}
                onClick={handleExport}
                > 
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                </svg>
                PDF proveedor
                </button>
            )}
        </div>
    );
};

export default SupplierPDF;
