const rolesPermissions = {
    ADMIN: {
      cliente: { create: true, read: true, update: true, delete: true },
      producto: { create: true, read: true, update: true, delete: true },
      proveedor: { create: true, read: true, update: true, delete: true },
      solicitud: { create: true, read: true, update: true, delete: true },
      costos: { create: true, read: true, update: true, delete: true },
      cotizaciones: { create: true, read: true, update: true, delete: true },
      ordenes: { create: true, read: true, update: true, delete: true },
      trazabilidad: { create: true, read: true, update: true, delete: true },
      usuarios: { create: true, read: true, update: true, delete: true }
    },
    DEV: {
      cliente: { create: true, read: true, update: true, delete: true },
      producto: { create: true, read: true, update: true, delete: true },
      proveedor: { create: true, read: true, update: true, delete: true },
      solicitud: { create: true, read: true, update: true, delete: true },
      costos: { create: true, read: true, update: true, delete: true },
      cotizaciones: { create: true, read: true, update: true, delete: true },
      ordenes: { create: true, read: true, update: true, delete: true },
      trazabilidad: { create: true, read: true, update: true, delete: true },
      usuarios: { create: true, read: true, update: true, delete: true }
    },
    CEO: {
      cliente: { create: true, read: true, update: true, delete: true },
      producto: { create: true, read: true, update: true, delete: true },
      proveedor: { create: true, read: true, update: true, delete: true },
      solicitud: { create: true, read: true, update: true, delete: true },
      costos: { create: true, read: true, update: true, delete: true },
      cotizaciones: { create: true, read: true, update: true, delete: true },
      ordenes: { create: true, read: true, update: true, delete: false },
      trazabilidad: { create: true, read: true, update: true, delete: true },
      usuarios: { create: true, read: true, update: true, delete: true }
    },
    DTCA: {
      cliente: { create: true, read: false, update: false, delete: false },
      producto: { create: true, read: true, update: true, delete: true },
      proveedor: { create: true, read: true, update: true, delete: true },
      solicitud: { create: false, read: false, update: false, delete: false },
      costos: { create: false, read: true, update: false, delete: false },
      cotizaciones: { create: false, read: false, update: false, delete: false },
      ordenes: { create: false, read: true, update: false, delete: false },
      trazabilidad: { create: false, read: true, update: false, delete: false },
      usuarios: { create: false, read: false, update: false, delete: false }
    },
    DTLO: {
      cliente: { create: false, read: true, update: true, delete: false },
      producto: { create: false, read: true, update: false, delete: false },
      proveedor: { create: true, read: false, update: false, delete: false },
      solicitud: { create: false, read: false, update: false, delete: false },
      costos: { create: false, read: false, update: false, delete: false },
      cotizaciones: { create: true, read: true, update: false, delete: false },
      ordenes: { create: true, read: true, update: true, delete: false },
      trazabilidad: { create: true, read: true, update: true, delete: false },
      usuarios: { create: false, read: false, update: false, delete: false }
    },
    ANAD: {
      cliente: { create: false, read: false, update: false, delete: false },
      producto: { create: false, read: true, update: false, delete: false },
      proveedor: { create: false, read: false, update: false, delete: false },
      solicitud: { create: false, read: false, update: false, delete: false },
      costos: { create: false, read: false, update: false, delete: false },
      cotizaciones: { create: false, read: true, update: false, delete: false },
      ordenes: { create: false, read: true, update: false, delete: false },
      trazabilidad: { create: false, read: true, update: false, delete: false },
      usuarios: { create: false, read: false, update: false, delete: false }
    },
    DTAD: {
      cliente: { create: false, read: false, update: false, delete: false },
      producto: { create: false, read: true, update: false, delete: false },
      proveedor: { create: false, read: false, update: false, delete: false },
      solicitud: { create: false, read: false, update: false, delete: false },
      costos: { create: false, read: false, update: false, delete: false },
      cotizaciones: { create: false, read: false, update: false, delete: false },
      ordenes: { create: false, read: true, update: false, delete: false },
      trazabilidad: { create: false, read: true, update: false, delete: false },
      usuarios: { create: false, read: false, update: false, delete: false }
    },
    DTFI: {
      cliente: { create: false, read: true, update: true, delete: false },
      producto: { create: false, read: false, update: false, delete: false },
      proveedor: { create: false, read: false, update: false, delete: false },
      solicitud: { create: false, read: false, update: false, delete: false },
      costos: { create: false, read: false, update: false, delete: false },
      cotizaciones: { create: false, read: false, update: false, delete: false },
      ordenes: { create: false, read: true, update: false, delete: false },
      trazabilidad: { create: false, read: false, update: false, delete: false },
      usuarios: { create: false, read: false, update: false, delete: false }
    },
    ANCO: {
      cliente: { create: false, read: true, update: false, delete: false },
      producto: { create: true, read: true, update: true, delete: true },
      proveedor: { create: true, read: true, update: true, delete: true },
      solicitud: { create: false, read: true, update: false, delete: false },
      costos: { create: true, read: true, update: true, delete: true },
      cotizaciones: { create: true, read: true, update: false, delete: false },
      ordenes: { create: false, read: true, update: false, delete: false },
      trazabilidad: { create: false, read: true, update: false, delete: false },
      usuarios: { create: false, read: false, update: false, delete: false }
    },
    DTCO: {
      cliente: { create: true, read: true, update: true, delete: true },
      producto: { create: false, read: true, update: false, delete: false },
      proveedor: { create: false, read: false, update: false, delete: false },
      solicitud: { create: true, read: true, update: true, delete: true },
      costos: { create: false, read: true, update: false, delete: false },
      cotizaciones: { create: true, read: true, update: true, delete: true },
      ordenes: { create: false, read: true, update: false, delete: false },
      trazabilidad: { create: false, read: true, update: false, delete: false },
      usuarios: { create: false, read: false, update: false, delete: false }
    },
    ASCO: {
      cliente: { create: true, read: true, update: true, delete: false },
      producto: { create: false, read: true, update: false, delete: false },
      proveedor: { create: false, read: false, update: false, delete: false },
      solicitud: { create: true, read: true, update: true, delete: false },
      costos: { create: false, read: true, update: false, delete: false },
      cotizaciones: { create: true, read: true, update: true, delete: false },
      ordenes: { create: false, read: true, update: false, delete: false },
      trazabilidad: { create: false, read: true, update: false, delete: false },
      usuarios: { create: false, read: false, update: false, delete: false }
    },
    AUBO: {
      cliente: { create: false, read: false, update: false, delete: false },
      producto: { create: false, read: false, update: false, delete: false },
      proveedor: { create: false, read: false, update: false, delete: false },
      solicitud: { create: false, read: false, update: false, delete: false },
      costos: { create: false, read: false, update: false, delete: false },
      cotizaciones: { create: false, read: false, update: false, delete: false },
      ordenes: { create: false, read: true, update: false, delete: false },
      trazabilidad: { create: false, read: true, update: false, delete: false },
      usuarios: { create: false, read: false, update: false, delete: false }
    },
    CLI: {
      cliente: { create: false, read: false, update: false, delete: false },
      producto: { create: true, read: false, update: false, delete: false },
      proveedor: { create: false, read: false, update: false, delete: false },
      solicitud: { create: false, read: false, update: false, delete: false },
      costos: { create: false, read: false, update: false, delete: false },
      cotizaciones: { create: false, read: false, update: false, delete: false },
      ordenes: { create: false, read: true, update: false, delete: false },
      trazabilidad: { create: false, read: false, update: false, delete: false },
      usuarios: { create: false, read: false, update: false, delete: false }
    },
  };
  
  export default rolesPermissions;