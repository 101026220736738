import React from 'react'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Spinner, Button, Nav, Tab, Row, Col, Form } from 'react-bootstrap';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import '../../appstyles/TrackingCSS/OrderTracking.css'

const requiredField = yup.string().required('Este campo es requerido');

const stepSchemas = {
1: yup.object().shape({
  supplier_consecutive: yup.number().typeError('Debe ser un número').required('Este campo es requerido').positive().integer(),
  freight_type: requiredField,
}),
2: yup.object().shape({
  initial_range_etd: yup.date().nullable().typeError('Debe ingresar una fecha').required('Este campo es requerido'),
  final_range_etd: yup.date().nullable().typeError('Debe ingresar una fecha').required('Este campo es requerido'),
}),
3: yup.object().shape({
  confirmation_etd: yup.date().nullable().typeError('Debe ingresar una fecha').required('Este campo es requerido'),
  carrier_name: requiredField,
}),
4: yup.object().shape({
  bl_number: requiredField,
  bl_date: yup.date().nullable().typeError('Debe ingresar una fecha').required('Este campo es requerido'),
  eta: yup.date().nullable().typeError('Debe ingresar una fecha').required('Este campo es requerido'),
}),
5: yup.object().shape({
  approved_documents: yup.boolean().default(false),
}),
6: yup.object().shape({
  bl_in_destination: yup.boolean().default(false),
  container_days: yup.number().typeError('Debe ser un número').required('Este campo es requerido').positive().integer(),
}),
};

function OrderTracking() {
  const axiosInstance = useAxiosPrivate();
  const { trackId } = useParams();
  const [tracking, setTracking] = useState(null);
  const [editingStep, setEditingStep] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(stepSchemas[currentStep]),
    defaultValues: {
      freight_type: 'Marítimo',
    }
    });

  // Fetch tracking details
    useEffect(() => {
      axiosInstance.get(`api/logistics-tracking/${trackId}/`)
        .then(response => {
          setTracking(response.data);
        })
        .catch(error => {
          console.error('Failed to fetch tracking details', error);
        });
    }, [trackId, axiosInstance]);

    //format date
    const formatDate = (date) => {
      const d = new Date(date);
      const month = `${d.getMonth() + 1}`.padStart(2, '0');
      const day = `${d.getDate()}`.padStart(2, '0');
      const year = d.getFullYear();
      return `${year}-${month}-${day}`;
    };

    //Deadline status
    const getDeadlineStatus = (status) => {
      switch (status) {
        case 'A tiempo':
          return {
            icon: (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="green" className="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
              </svg>
            ),
            color: 'green',
            backgroundColor: '#e2f8d4'
          };
        case 'Advertencia':
          return {
            icon: (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="orange" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
              </svg>
            ),
            color: 'orange',
            backgroundColor: '#f7eeca'
          };
        case 'Retraso':
          return {
            icon: (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" className="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            ),
            color: 'red',
            backgroundColor: '#f7d3ca'
          };
        default:
          return {
            icon: null,
            color: 'black'
          };
      }
    };
  
    if (!tracking) {
      return(
      <div className='loading-container'>
        <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
        <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
      </div>
      );
    }
    
    const stepMap = {
        first: 1,
        second: 2,
        third: 3,
        fourth: 4,
        fifth: 5,
        sixth: 6,
    };

    const stepFields = {
      2: ["supplier_consecutive", "freight_type"],
      3: ["supplier_consecutive", "freight_type", "initial_range_etd", "final_range_etd"],
      4: ["supplier_consecutive", "freight_type", "initial_range_etd", "final_range_etd", "confirmation_etd", "carrier_name"],
      5: ["supplier_consecutive", "freight_type", "initial_range_etd", "final_range_etd", "confirmation_etd", "carrier_name", "bl_number", "bl_date", "eta"],
      6: ["supplier_consecutive", "freight_type", "initial_range_etd", "final_range_etd", "confirmation_etd", "carrier_name", "bl_number", "bl_date", "eta", "approved_documents"],
    };
  
    const isStepCompleted = (step) => {
      const fields = stepFields[step];
      return fields.every(field => tracking[field] !== null && tracking[field] !== undefined && tracking[field] !== '');
    };

    // Edit tracking step
    const handleEdit = (step) => {
      setEditingStep(step);
      reset(tracking);
    };

    const handleCancel = () => {
      setEditingStep(null);
    };
    
    // Submit tracking step
    const onSubmit = async (data) => {
      try {
        setLoading(true);
        let endpoint = "";
        // Format only the date fields present in data
        const formattedData = {
          tracking_id: trackId,
          ...Object.fromEntries(
            Object.entries(data).map(([key, value]) =>
              ["initial_range_etd", "final_range_etd", "confirmation_etd", "bl_date", "eta"].includes(key)
                ? [key, formatDate(value)]
                : [key, value]
            )
          ),
        };
        console.log(formattedData);

        // Define the fields required per step
        const stepFields = {
          1: ["tracking_id", "supplier_consecutive", "freight_type"],
          2: ["tracking_id", "initial_range_etd", "final_range_etd"],
          3: ["tracking_id", "confirmation_etd", "carrier_name"],
          4: ["tracking_id", "bl_number", "bl_date", "eta"],
          5: ["tracking_id", "approved_documents"],
          6: ["tracking_id", "bl_in_destination", "container_days"],
        };

        // Extract only the necessary fields for the current step
        const filteredData = Object.fromEntries(
          Object.entries(formattedData).filter(([key]) => stepFields[currentStep]?.includes(key))
        );

        switch (currentStep) {
          case 1:
            endpoint = `api/logistics-tracking/assign/`;
            break;
          case 2:
            endpoint = `api/logistics-tracking/sign/`;
            break;
          case 3:
            endpoint = `api/logistics-tracking/confirm/`;
            break;
          case 4:
            endpoint = `api/logistics-tracking/bl/`;
            break;
          case 5:
            endpoint = `api/logistics-tracking/approve-documents/`;
            break;
          case 6:
            endpoint = `api/logistics-tracking/originals-delivered/`; 
            {/*
            const originalsDeliveredResponse = await axiosInstance.post(
              `api/logistics-tracking/originals-delivered/`,
              { ...filteredData, tracking_id: trackId }
            );
            
            const closeResponse = await axiosInstance.post(
              `api/logistics-tracking/close/`,
              { ...filteredData, tracking_id: trackId }
            );
              
          if (originalsDeliveredResponse.status === 200 && closeResponse.status === 200) {
              alert("El seguimiento se finalizó exitosamente!");
              return;
          } else {
              throw new Error("Error in closing process.");
          }*/}
        }
        
        if (endpoint) {
          const response = await axiosInstance.post(endpoint, { ...filteredData, tracking_id: trackId });
          if (response.status === 200) {
            setTimeout(() => {
              setLoading(false);
              setEditingStep(null);
            }, 1000); // Delay of 2 seconds 
            window.location.reload();         
          }
        }
      } catch (error) {
        console.error("Submission error:", error);
        alert("An error occurred. Please try again.");
      }
    };

  return (
    <div className='tracking-container'>
    <h4 style={{fontWeight: '700'}}>Trazabilidad</h4>
    <div className='delay-warning' style={{backgroundColor: getDeadlineStatus(tracking.deadline_status).backgroundColor}}>
    {tracking?.deadline_status && (
      <>
        {getDeadlineStatus(tracking.deadline_status).icon}
        <span style={{ color: getDeadlineStatus(tracking.deadline_status).color }}>
          {tracking.deadline_status}
        </span>
      </>
      )}
    </div>

    <Tab.Container id="left-tabs-example" defaultActiveKey="first" onSelect={(key) => setCurrentStep(stepMap[key])}>
    <Row>
        {/*<Col sm={2}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
        </svg>

        </Col>*/}

        <Col sm={6}>
        <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="first">Asignado</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" disabled={!isStepCompleted(2)}>Contrato Firmado</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third" disabled={!isStepCompleted(3)}>Confirmación</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fourth" disabled={!isStepCompleted(4)}>Recepción de BL</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fifth" disabled={!isStepCompleted(5)}>Aprobación de Documentos</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="sixth" disabled={!isStepCompleted(6)}>Entrega de Originales</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="seventh" disabled>Cerrado</Nav.Link>
            </Nav.Item>
        </Nav>
        </Col>

        <Col sm={6}>
        <Tab.Content>
            <Tab.Pane eventKey="first">
              <div className='tracking-details'>
                <h5 style={{fontWeight: '700'}}>Asignado</h5>
                {editingStep === "first" ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId='supplier_consecutive'>
                      <Form.Label>Consecutivo del proveedor</Form.Label>
                      <Form.Control type="number" name="supplier_consecutive" {...register('supplier_consecutive')} isInvalid={!!errors.supplier_consecutive}/>
                      <Form.Control.Feedback type="invalid">
                        {errors.supplier_consecutive?.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId='freight_type'>
                      <Form.Label>Tipo de carga</Form.Label>
                      <Form.Select type="text" name="freight_type" {...register('freight_type')} isInvalid={!!errors.freight_type}>
                        <option value="Marítimo">Marítimo</option>
                        <option value="Aereo">Aereo</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.freight_type?.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Button
                      variant="secondary"
                      type="Submit"
                      style={{
                        width: '20%',
                        backgroundColor: '#056664',
                        border: ' solid 2px #025C5A',
                      }}
                    >
                      {loading ? 'Cargando...' : 'Guardar'}
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={handleCancel}
                      style={{
                        width: '20%',
                        backgroundColor: '#056664',
                        border: ' solid 2px #025C5A',
                      }}
                    >
                      Cancelar
                    </Button>
                  </Form>
                ) : (
                <>  
                  <p><strong>Consecutivo del Proveedor: </strong> {tracking?.supplier_consecutive == null ? 'Dato no proporcionado' : tracking.supplier_consecutive} </p>
                  <p><strong>Tipo de flete: </strong> {tracking?.freight_type === '' ? 'Dato no proporcionado' : tracking.freight_type}</p>
                </>
                )}
              </div>
              {editingStep !== "first" && (
              <Button
                variant="primary"
                style={{ 
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                }} 
                onClick={() => handleEdit("first")} 
              >
                Editar
              </Button>
              )}
            </Tab.Pane>

            <Tab.Pane eventKey="second">
              <div className='tracking-details'>
              <h5 style={{fontWeight: '700'}}>Contrato Firmado</h5>
              {editingStep === "second" ? (
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId='initial_range_etd'>
                  <Form.Label>Rango Inicial de ETD</Form.Label>
                  <Form.Control 
                    type="date" 
                    name="initial_range_etd" 
                    {...register('initial_range_etd')} 
                    isInvalid={!!errors.initial_range_etd}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.initial_range_etd?.message}
                </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='final_range_etd'>
                  <Form.Label>Rango Final de ETD</Form.Label>
                  <Form.Control 
                    type="date" 
                    name="final_range_etd" 
                    {...register('final_range_etd')} 
                    isInvalid={!!errors.final_range_etd}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.final_range_etd?.message}
                </Form.Control.Feedback>
                </Form.Group>

                <Button
                  variant="secondary"
                  type="Submit"
                  style={{
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                  }}
                >
                  {loading ? 'Cargando...' : 'Guardar'}
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleCancel}
                  style={{
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                  }}
                >
                  Cancelar
                </Button>
              </Form>
              ) : (
              <>
                <p><strong>Rango inicial ETD: </strong> {tracking?.initial_range_etd == null ? 'Dato no proporcionado' : tracking.initial_range_etd}</p>
                <p><strong>Rango final ETD: </strong>{tracking?.final_range_etd == null ? 'Dato no proporcionado' : tracking.final_range_etd}</p>
              </>
                )}
              </div>
              {editingStep !== "second" && (
              <Button
                variant="primary"
                style={{ 
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                }}
                onClick={() => handleEdit("second")} 
                
              >
                Editar
              </Button>
              )}
            </Tab.Pane>

            <Tab.Pane eventKey="third">
              <div className='tracking-details'>
              <h5 style={{fontWeight: '700'}}>Confirmación</h5>
              {editingStep === "third" ? (
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId='confirmation_etd'>
                  <Form.Label>Confirmación ETD</Form.Label>
                  <Form.Control 
                    type="date" 
                    name="confirmation_etd" 
                    {...register('confirmation_etd')} 
                    isInvalid={!!errors.confirmation_etd}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmation_etd?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='carrier_name'>
                  <Form.Label>Nombre de transportador</Form.Label>
                  <Form.Control type="text" name="carrier_name" {...register('carrier_name')} isInvalid={!!errors.carrier_name} />
                  <Form.Control.Feedback type="invalid">
                    {errors.carrier_name?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  variant="secondary"
                  type="Submit"
                  style={{
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                  }}
                >
                  {loading ? 'Cargando...' : 'Guardar'}
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleCancel}
                  style={{
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                  }}
                >
                  Cancelar
                </Button>
                </Form>
              ) : (
              <>
                <p><strong>ETD de confirmación: </strong>{tracking?.confirmation_etd == null ? 'Dato no proporcionado' : tracking.confirmation_etd}</p>
                <p><strong>Nombre de carrier: </strong>{tracking?.carrier_name == null ? 'Dato no proporcionado' : tracking.carrier_name}</p>
              </>
                )}
              </div>
              {editingStep !== "third" && (
              <Button
                variant="primary"
                style={{ 
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                }}
                onClick={() => handleEdit("third")} 
                
              >
                Editar
              </Button>
              )}
            </Tab.Pane>

            <Tab.Pane eventKey="fourth">
              <div className='tracking-details'>
                <h5 style={{fontWeight: '700'}}>Recepción de BL</h5>
                {editingStep === "fourth" ? (
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId='bl_number'>
                  <Form.Label>Número BL</Form.Label>
                  <Form.Control type="text" name="bl_number" {...register('bl_number')} isInvalid={!!errors.bl_number} />
                  <Form.Control.Feedback type="invalid">
                    {errors.bl_number?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='bl_date'>
                  <Form.Label>Fecha BL</Form.Label>
                  <Form.Control 
                    type="date" 
                    name="bl_date" 
                    {...register('bl_date')} 
                    isInvalid={!!errors.bl_date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.bl_date?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='eta'>
                  <Form.Label>ETA</Form.Label>
                  <Form.Control 
                    type="date" 
                    name="eta" 
                    {...register('eta')} 
                    isInvalid={!!errors.eta}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.eta?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  variant="secondary"
                  type="Submit"
                  style={{
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                  }}
                >
                  {loading ? 'Cargando...' : 'Guardar'}
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleCancel}
                  style={{
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                  }}
                >
                  Cancelar
                </Button>
              </Form>
              ) : (
              <>
                <p><strong>Número de BL: </strong>{tracking?.bl_number == null ? 'Dato no proporcionado' : tracking.bl_number}</p>
                <p><strong>Fecha de BL: </strong>{tracking?.bl_date == null ? 'Dato no proporcionado' : tracking.bl_date}</p>
                <p><strong>ETA: </strong>{tracking?.eta == null ? 'Dato no proporcionado' : tracking.eta}</p>
              </>
                )}
              </div>
              {editingStep !== "fourth" && (
              <Button
                variant="primary"
                style={{ 
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                }}
                onClick={() => handleEdit("fourth")} 
                
              >
                Editar
              </Button>
              )}
            </Tab.Pane>

            <Tab.Pane eventKey="fifth">
              <div className='tracking-details'>
                <h5 style={{fontWeight: '700'}}>Aprobación de Documentos</h5>
                {editingStep === "fifth" ? (
                <Form onSubmit={handleSubmit(onSubmit)}>
                {['checkbox'].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Group controlId="approved_documents">
                  <Form.Check
                    inline
                    label="Los documentos han sido aprobados"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                    {...register('approved_documents')}
                  />
                  </Form.Group>
                </div>
                ))}
                <Button
                  variant="secondary"
                  type="Submit"
                  style={{
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                  }}
                >
                  {loading ? 'Cargando...' : 'Guardar'}
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleCancel}
                  style={{
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                  }}
                >
                  Cancelar
                </Button>
                </Form>
              ) : (
              <>
                <p>
                  {tracking?.approved_documents ? (
                    <>Los documentos se encuentrán <strong>aprobados</strong></>
                  ) : (
                    <>Los documentos <strong>no han sido aprobados</strong></>
                  )}
                </p>
              </>
                )}
              </div>
              {editingStep !== "fifth" && (
              <Button
                variant="primary"
                style={{ 
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                }}
                onClick={() => handleEdit("fifth")} 
                
              >
                Editar
              </Button>
              )}
            </Tab.Pane>

            <Tab.Pane eventKey="sixth">
              <div className='tracking-details'>
                <h5 style={{fontWeight: '700'}}>Entrega de Originales</h5>
                {editingStep === "sixth" ? (
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId='container_days'>
                  <Form.Label>Días de contenedor</Form.Label>
                  <Form.Control type="number" name="container_days" {...register('container_days')} isInvalid={!!errors.container_days}/>
                  <Form.Control.Feedback type="invalid">
                    {errors.container_days?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                {['checkbox'].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Group controlId="bl_in_destination">
                  <Form.Check
                    inline
                    label="El BL se encuentra en destino"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                    {...register('bl_in_destination')}
                  />
                  </Form.Group>
                </div>
                ))}

                <Button
                  variant="secondary"
                  type="Submit"
                  style={{
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                  }}
                >
                  {loading ? 'Cargando...' : 'Guardar'}
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleCancel}
                  style={{
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                  }}
                >
                  Cancelar
                </Button>
                </Form>
              ) : (
              <>
                <p><strong>Días de contenedor: </strong>{tracking?.container_days == null ? 'Dato no proporcionado' : tracking.container_days}</p>
                <p>
                  {tracking?.bl_in_destination ? (
                    <>El BL se encuentra en el destino</>
                  ) : (
                    <>El BL <strong>NO</strong> se encuentra en el destino</>
                  )}
                </p>
              </>
                )}
              </div>
              {editingStep !== "sixth" && (
              <Button
                variant="primary"
                style={{ 
                    width: '20%',
                    backgroundColor: '#056664',
                    border: ' solid 2px #025C5A',
                }}
                onClick={() => handleEdit("sixth")} 
                
              >
                Editar
              </Button>
              )}
            </Tab.Pane>

          </Tab.Content>
        </Col>
    </Row>
    </Tab.Container>
    </div>
  )
}

export default OrderTracking