import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import TrackingDetails from './TrackingDetails';
import OrderTracking from './OrderTracking';
import '../../appstyles/ClientsCSS/ClientsDetails.css'


function TrackingTraceability() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data fetching
    const fetchData = async () => {
      try {
        // Fetch data for TrackingDetails and OrderTracking
        // Simulate with a timeout
        await new Promise(resolve => setTimeout(resolve, 2000));
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch data', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className='loading-container'>
        <p style={{ fontFamily: 'Roboto, sans-serif' }}>Cargando...</p>
        <Spinner animation="border" variant="info" style={{ width: '4.5rem', height: '4.5rem' }} />
      </div>
    );
  }


  return (
    <>
      <div className='maestros-details'>
        <h2>Seguimiento de Trazabilidad</h2>
        <hr />
        <TrackingDetails />
        <hr />
        <OrderTracking />
        <hr />
        <Button 
          variant="secondary" 
          style={{marginTop: '20px', width: '20%'}}
          onClick={() => navigate(`/trackinglist`)}
          >
        Volver
        </Button>
      </div>
    </>
  )
}

export default TrackingTraceability