import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import { Spinner, Button, Row, Modal, Form } from 'react-bootstrap';
import '../../appstyles/ClientsCSS/ClientsDetails.css'
import '../../appstyles/QuotesCSS/OrderDetails.css'

function  TrackingOrderDetails() {
  const axiosInstance = useAxiosPrivate();
  const { trackId } = useParams();
  const [orderId, setOrderId] = useState(null);
  const [quote, setQuote] = useState(null);
  const [tracking, setTracking] = useState(null);
  const [news, setNews] = useState('');

  const [showNews, setShowNews] = useState(false);
  const handleClose = () => setShowNews(false);
  const handleShow = () => setShowNews(true);

  // Fetch tracking details 
  useEffect(() => {
    axiosInstance.get(`api/logistics-tracking/${trackId}/`)
      .then(response => {
        const trackingDetails = response.data;
        setTracking(trackingDetails);
        setOrderId(trackingDetails.quote_id);
      })
      .catch(error => {
        console.error('Failed to fetch tracking details', error);
      });
  }, [trackId, axiosInstance]);

  // Fetch order details
  useEffect(() => {
    if (!orderId) return; 
    axiosInstance.get(`api/quote/${orderId}/`)
      .then(response => {
        setQuote(response.data);
      })
      .catch(error => {
        console.error('Failed to fetch quote details', error);
      });
  }, [orderId, axiosInstance]);

  const handleSaveNews = () => {
    axiosInstance.patch(`api/logistics-tracking/${trackId}/`, {
      news: news,
      })
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        console.error('Failed to save supplier consecutive', error);
      });
  };

  if (!quote) {
    return(
    <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
    </div>
    );
  }

  return (
    <div className='maestros-details' style={{padding: '0px'}}>

        <h4 style={{fontWeight: '700'}}>Detalles de Orden de Compra No. {quote.quote_id} </h4>
        
        <div style={{display: 'flex', flexDirection: 'row'}}>
        <div className='first-col'>
            <p><strong>Consecutivo:</strong> {quote.consecutive} </p>
            <p><strong>Cliente:</strong> {quote.client_name} </p>
            <p><strong>Proveedor:</strong> {quote.supplier_name} </p>
            <p><strong>Incoterm:</strong> {quote.incoterm} </p>
        </div>  

        <div className='second-col'>
            <p><strong>Consecutivo del cliente:</strong> {quote.client_consecutive} </p>
            <p><strong>Fecha solicitada:</strong> {quote.requested_date} </p>
            <p><strong>Ciudad de llegada:</strong> {quote.arrival_city} </p>
        </div>
        </div>

        <h4 style={{fontWeight: '700', color: '#07514F'}}>{quote.products.length > 1 ? 'Productos' : 'Producto'}</h4>
        {quote.products.map(product => (
        <Row key={product.id}>
        <div className='first-col' style={{margin: '0px'}}>
            <p><strong>Nombre del producto:</strong> {product.product_name} </p>
        </div>

        <div className='second-col' style={{padding: '0px'}}>
            <p><strong>Fabricante:</strong> {product.product_manufacturer}</p>
        </div>
        </Row>
        ))}

        <hr />
        <div className='orders-details-actions' style={{marginBottom: '10px'}}>
        <h4 style={{fontWeight: '700'}}>Novedades</h4>
        <div className='orders-actions'>
            <button 
            className='btn btn-primary' 
            style={{backgroundColor: '#025C5A', borderColor: '#025C5A'}}
            onClick={handleShow}
            > 
                Editar
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16" style={{marginLeft: '10px'}}> 
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                </svg>
            </button>
        </div>
        </div>       
        <p> {tracking.news ? tracking.news : 'No hay novedades para esta trazabilidad' } </p>

        <Modal show={showNews} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Novedades de la Trazabilidad
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Escribe las novedades asociadas a esta trazabilidad</p>
          <Form.Group controlId="news">
          <Form.Control 
            type="textarea" 
            placeholder="Novedades"
            value={news} 
            onChange={(e) => setNews(e.target.value)} 
          />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="success" onClick={handleSaveNews}>
            Guardar
          </Button>
        </Modal.Footer>
        </Modal>
    </div>
  )
}

export default TrackingOrderDetails 